.sworn {
  padding: 160px 7.5vw;

  &_header {
    @include header;
  }

  &_list {
    @include circle;
  }
}

@media (max-width: 792px) {
  .sworn {
    padding: 13vh 0 13vh 0;

    &_header {
      @include mobileHeader;
    }

    &_list {
      @include mobileCircle;
    }
  }
}

@media (min-width: 793px) and (max-width: 1100px) {
  .sworn {
      padding: 160px 9vw;
      
      &_header {
      font-size: 1.7rem
    }

    &_list {
      @include tabletCircle;
    }
  }
}

@media (min-width: 1101px) and (max-width: 1700px) {
  .sworn {
    padding: 160px 8vw;

    &_list {
      @include ipadCircle;
    }
  }
}