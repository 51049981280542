@use "sass:map";

.footer {
  padding: 2rem 180px;
  @include text;
  font-size: 1.1rem;
  border-top: 2px solid map.get($colors, second-color);
  background-color: #6889c7;

  &_content {
    display: flex;
    justify-content: space-between;

    &--data {
      h4 {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }

  .copyright {
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
  }
}

@media (max-width: 792px) {
  .footer {
    padding: 0;
    @include mobileText;
    font-size: .7rem;
    text-align: center;


    &_content {
      flex-direction: column;

      &--data {
        h4 {
          font-size: .9rem;
          padding-top: 2rem;
        }
      }
    }

    .copyright {
      margin-top: 3rem;
      padding-bottom: 1.5rem;
      font-size: .6rem;
    }
  }
}

@media (min-width: 792px) and (max-width: 1100px) {
  .footer {
    padding: 2rem 80px 1rem 80px;
    font-size: .9rem;

    &_content {
      &--data {
        h4 {
          font-size: 1.1rem;
        }
      }
    }

    .copyright {
      font-size: .8rem;
    }
  }
}