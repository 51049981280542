@use "sass:map";

.App {
  background-color: map.get($colors, background-color);
}

::-webkit-scrollbar {
  width: 0.9rem;
  background-color: map.get($colors, background-color);
}

::-webkit-scrollbar-thumb {
  background-color: map.get($colors, second-color);
  border-radius: 20px;
  border: 3px solid map.get($colors, background-color);
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

html {
  scroll-behavior: smooth;
}
