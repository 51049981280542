.written {
  padding: 90px 7.5vw 0 7.5vw;

  &_header {
    @include header;
  }

  &_list {
    @include circle;
  }
}

@media (max-width: 792px) {
  .written {
    padding: 10vh 0 0 0;

    &_header {
      @include mobileHeader;
    }

    &_list {
      @include mobileCircle;
    }
  }
}

@media (min-width: 793px) and (max-width: 1100px) {
  .written {
    padding: 90px 9vw 0 9vw;
    
    &_header {
      font-size: 1.7rem;
    }

    &_list {
      @include tabletCircle;
    }
  }
}

@media (min-width: 1101px) and (max-width: 1700px) {
  .written {
    &_list {
      @include ipadCircle;
    }
  }
}