@use "sass:map";

.examples {
  padding: 70px 7.5vw 80px 7.5vw;

  &_header {
    @include header;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    .text {
      @include text;
      margin: 0 1.8vw 5% 1.8vw;
      width: 37vw;
      text-align: justify;
    }
  }
}

@media (max-width: 792px) {
  .examples {
    padding: 0;

    &_header {
      @include mobileHeader;
      padding-bottom: .5rem;
    }

    &_list {
      flex-direction: column;

      .text {
        @include mobileText;
        margin: 0 0 1.1rem 0;
        width: 84vw;
        text-align: center;

        &:after {
          content: "*";
          display: block;
          margin-top: 2em;
        }
      }

      .text--no {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 793px) and (max-width: 1100px) {
  .examples {
    padding: 70px 80px 80px 80px;

    &_header {
      font-size: 1.7rem;
    }

    &_list {
      justify-content: space-between;
      align-items: flex-start;

      .text {
        font-size: 1.6vw;
        margin: 0 0 5rem 0;
        width: 26rem;
      }
    }
  }
}