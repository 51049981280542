@use "sass:map";

.landing {
  color: map.get($colors, font-color);
  padding: 110px 220px 90px 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_text {
    &--header {
      font-family: "Libre Baskerville", serif;
      font-size: 2.7vw;
      font-weight: bolder;
      letter-spacing: 0.3vw;
      line-height: 3.2rem;
    }

    &--paragraph {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1.7vw;
      letter-spacing: 0.13vw;
      line-height: 2.2rem;
    }

    .hmm {
      display: flex;
      align-items: flex-start;
    }

    .cta {
      padding-top: 4rem;
      text-align: center;
    }

    .btn {
      @include button();
      margin-top: 1rem;
    }
  }

  .photo {
    width: 31vw;
  }
}

@media (max-width: 792px) {
  .landing {
    padding: 22vh 0 10vh 0;
    margin: 0 auto;
    text-align: center;
    justify-content: center;

    &_text {
      &--header {
        font-size: 1.4rem;
        letter-spacing: 0.2rem;
        line-height: 2.2rem;
      }

      &--paragraph {
        font-size: 1rem;
        letter-spacing: 0.1rem;
        line-height: 1.2rem;
        margin-top: 1rem;
      }

      .hmm {
        display: block;

        .cta {
          padding-top: 4rem;
          text-align: center;

          .paragraph-two {
            padding-top: 1rem;
          }
        }
      }

      .btn {
        @include mobileButton;
        margin: 1rem 0 0 0;
      }
    }

    .photo {
      display: none;
    }
  }
}

@media (min-width: 793px) and (max-width: 1100px) {
  .landing {
    padding: 150px 80px 90px 80px;

    &_text {
      &--header {
        font-size: 1.8rem;
        line-height: 3rem;
      }

      &--paragraph {
        font-size: 1.6rem;
      }

      .paragraph-two {
        padding-top: 3rem;
      }
    }

    .photo {
      height: 450px;
    }
  }
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .landing {
    padding: 13% 7.5vw 90px 7.5vw;
      &_text {
          &--header {
            font-size: 2.9vw;
          }
      
          &--paragraph {
            font-size: 1.9vw;
          }
          .btn {
            margin-top: 1.2rem;
          }
        }
      
        .photo {
          width: 33vw;
        }
  }
}

@media (min-width: 1401px) and (max-width: 1700px) {
  .landing {
    padding: 8% 7.5vw 90px 7.5vw;
    
    .photo {
      width: 30vw;
    }
  }
}