.oral {
  padding: 0 20vw 75px 20vw;

  &_header {
    @include header;
  }

  &_list {
    @include circle;
  }
}

@media (max-width: 792px) {
  .oral {
    padding: 0 0 3vh 0;

    &_header {
      @include mobileHeader;
    }

    &_list {
      @include mobileCircle;
    }
  }
}

@media (min-width: 793px) and (max-width: 1100px) {
  .oral {
    &_header {
      @include header;
      font-size: 1.7rem
    }

    &_list {
      @include tabletCircle;
    }
  }
}

@media (min-width: 1101px) and (max-width: 1700px) {
  .oral {
    &_list {
      @include ipadCircle;
    }
  }
}