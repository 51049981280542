@use "sass:map";

// BUTTON //
@mixin button {
  background-color: map.get($colors, accent-color);
  width: 212px;
  height: 47px;
  border-radius: 29px;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.13rem;

  &:hover {
    border: 3px solid goldenrod;
  }

  &:active {
    box-shadow: inset 0 0 5px;
    font-size: 1rem;
  }
}

@mixin mobileButton {
  width: 180px;
  height: 40px;
  font-size: 1rem;
  letter-spacing: 0.1rem;

  &:active {
    box-shadow: inset 0 0 5px;
    font-size: 1rem;
  }
}


// HEADER //
@mixin header {
  font-family: "Libre Baskerville", serif;
  font-size: 2.2vw;
  font-weight: bolder;
  letter-spacing: 0.25vw;
  line-height: 3rem;
  color: map.get($colors, font-color);
  text-align: center;
  margin-bottom: 5rem;

  &::after,
  &::before {
    background-color: map.get($colors, accent-color);
    content: "";
    display: inline-block;
    height: 5px;
    width: 4rem;
    margin: 0 20px 8px 15px;
  }
}

@mixin mobileHeader {
  font-size: 1.1rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  text-decoration: underline map.get($colors, accent-color) 3px;
  text-underline-offset: 0.4rem;
  letter-spacing: 0.1rem;

  &::before,
  &::after {
    content: none;
  }
}


// CIRCLE //
@mixin circle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;

  .border {
    border: 2px solid map.get($colors, accent-color);
    border-radius: 50%;
    margin: 17.5px;
  }

  .circle {
    background-color: map.get($colors, second-color);
    height: 24rem;
    width: 24rem;
    border-radius: 50%;
    margin: 17.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.11rem;
    line-height: 1.4rem;

    .title {
      color: map.get($colors, font-color);
      font-weight: bold;
      font-size: 1.5rem;
      padding-bottom: 2px;
      border-bottom: 3px solid map.get($colors, accent-color);
    }

    .text {
      font-size: 1.1rem;
      font-weight: normal;
      color: map.get($colors, font-color);
      margin-top: 2rem;
    }
  }
}

@mixin tabletCircle {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      grid-auto-rows: minmax(100px, auto);

  .border {
    border: 2px solid map.get($colors, accent-color);
    border-radius: 50%;
  }

  .circle {
    background-color: map.get($colors, second-color);
    height: 22rem;
    width: 22rem;
    border-radius: 50%;
    margin: 17.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.11rem;
    line-height: 1.4rem;

    .title {
      color: map.get($colors, font-color);
      font-weight: bold;
      font-size: 1.3rem;
      padding-bottom: 2px;
      border-bottom: 3px solid map.get($colors, accent-color);
    }

    .text {
      font-size: 1rem;
      font-weight: normal;
      color: map.get($colors, font-color);
      margin-top: 2rem;
    }
  }
}

@mixin mobileCircle {
  flex-wrap: nowrap;
  justify-content: normal;
  overflow-x: scroll;
  margin: 0 auto;
  width: 95vw;

  .border {
    margin: 15px;
  }

  .circle {
    height: 16.5rem;
    width: 16.5rem;
    line-height: 1.1rem;
    margin: 15px;

    .title {
      font-size: 1rem;
      padding-bottom: 2px;
    }

    .text {
      font-size: 0.7rem;
      margin-top: 1.2rem;
    }
  }
}

@mixin ipadCircle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;

  .border {
    border: 2px solid map.get($colors, accent-color);
    border-radius: 50%;
    margin: 13px;
  }

  .circle {
    background-color: map.get($colors, second-color);
    height: 23vw;
    width: 23vw;
    border-radius: 50%;
    margin: 17.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.11rem;
    line-height: 1.4rem;

    .title {
      color: map.get($colors, font-color);
      font-weight: bold;
      font-size: 1.3vw;
      padding-bottom: 2px;
      border-bottom: 3px solid map.get($colors, accent-color);
    }

    .text {
      font-size: 1vw;
      font-weight: normal;
      color: map.get($colors, font-color);
      margin-top: 8%;
    }
  }
}


// TEXT //
@mixin text {
  font-size: 1.3vw;
  font-weight: normal;
  color: map.get($colors, font-color);
  margin-top: 2rem;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 108%;
  letter-spacing: 0.1vw;
}

@mixin mobileText {
  font-size: 0.8rem;
  line-height: 1.2rem;
}
