@use "sass:map";

.about {
  padding: 85px 7.5vw 100px 7.5vw;

  &_header {
    @include header;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .profile {
      box-sizing: border-box;
      width: 34.5vw;
      border: 2px double map.get($colors, second-color);
    }

    .text {
      @include text;
      margin: 0 0 0 3vw;
      width: 45vw;
      text-align: justify;
    }
  }
}

@media (max-width: 792px) {
  .about {
    padding: 10vh 0 13vh 0;

    &_header {
      @include mobileHeader;
    }

    &_content {
      flex-direction: column;

      .profile {
        width: 84vw;
      }

      .text {
        @include mobileText;
        margin: 3vh 0 0 0;
        width: 84vw;
        letter-spacing: 0;
        font-size: .9rem;
      }
    }
  }
}

@media (min-width: 793px) and (max-width: 1100px) {
  .about {
    &_header {
      font-size: 1.7rem;
    }

    &_content {
      display: inline;

      .profile {
        height: 24rem;
        margin: .3rem 2rem 1rem 0;
        float: left;
      }

      .text {
        font-size: 1.6vw;
        width: auto;
        line-height: 1.4rem;
        margin: 0;
      }
    }
  }
}

@media (min-width: 1101px) and (max-width: 1700px) {
  .about {
    &_content {
      .profile {
        width: 36vw;
      }

      .text {
        @include text;
        margin: 0 0 0 3vw;
        text-align: justify;
      }
    }
  }
}
